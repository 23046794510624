<template>
<v-row>
  <v-col>
    No detailed data for this alert.
  </v-col>
</v-row>
</template>

<script>
export default {
  name: 'DefaultAlertType'
}
</script>

<style scoped>

</style>
